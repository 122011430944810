<style lang="scss" >
.custom-add-dialog, .custom-search-dialog{
	.el-form-item {
		margin-bottom: 8px !important;
	}
	.el-table__body-wrapper is-scrolling-none {
		height: 56vh;
		overflow: auto;
	}
	.el-dialog__header {
		background: url('../assets/img/dialog-head.png') no-repeat center / 110% 110%;
	}
	.el-dialog__title {
		color: #fff;
	}
	.el-dialog__close {
		color: #fff !important;
	}
	.box {
		max-height: 60vh;
		overflow-y: auto;
	}
	.my-el-input-number[data-unit] {
		--el-input-number-unit-offset-x: 35px;
		position: relative;
	}
	.my-el-input-number[data-unit]::after {
		content: attr(data-unit);
		height: 100%;
		display: flex;
		align-items: center;
		position: absolute;
		top: 0;
		right: var(--el-input-number-unit-offset-x);
		color: #999999;
	}
	.my-el-input-number[data-unit] .el-input__inner {
		padding-left: 30px;
		padding-right: calc(var(--el-input-number-unit-offset-x) + 30px);
	}
}
</style>
<template>
	<div class="custom-add-edit">
		<el-dialog custom-class="custom-add-dialog" :title="title" :visible.sync="open" 
			width="50%" v-dialogDrag :closeOnClickModal="false" :before-close="cancel" top="15vh">
			<div class="box">
				<el-form ref="form" label-width="80px" v-loading="formLoading" style="display: flex; flex-wrap: wrap; justify-content: space-between;">
					<el-form-item v-for="(item, i) in colsList" :key="i" :label="item.name" label-width="120px"
						style="width: 48%;" v-show="item.onDisplay" :required="item.onRequired">
						<slot :name="'slot_' + item.fieldName" :form="param">
							<template slot="label">
								<span :title="item.name">{{ item.name }}：</span>
							</template>
							<el-input :title="param[item.fieldName]" v-if="item.operateType == 'select'"
								v-model="param[item.fieldName]" clearable suffix-icon="el-icon-search" readonly
								@click.native="openFuzzy(item)" :placeholder="`请选择${item.name}`"></el-input>
							<el-input v-else-if="item.operateType == 'string'" :disabled="item.noBatch"
								v-model="param[item.fieldName]" :placeholder="`请输入${item.name}`"></el-input>
							<el-input-number style="width:100%" v-model="param[item.fieldName]"
								v-else-if="item.operateType === 'number'" :min="0" :disabled="item.noBatch"
								:data-unit="item.fieldName == 'yearAmount' ? '万元' : '元'"
								:class="item.fieldName == 'yearAmount' || item.fieldName == 'amount' ? 'my-el-input-number' : ''">
							</el-input-number>
							<el-date-picker style="width: 100%;" v-model="param[item.fieldName]" 
								v-else-if="item.operateType === 'date'"
								type="datetime" value-format="yyyy-MM-dd-HH-mm-ss"
								:disabled="item.noBatch"></el-date-picker>
							<el-date-picker style="width: 100%;" v-model="param[item.fieldName]"
								v-else-if="item.operateType === 'year'" placeholder="选择年份"
								type="year" :disabled="item.noBatch" format="yyyy" value-format="yyyy"></el-date-picker>
							
							<template v-else-if="item.operateType === 'file' && open">
								<edit-upload :value.sync="param[item.fieldName]" :form="param" :col="item"></edit-upload>
							</template>
							<!-- <el-date-picker style="width: 100%;" v-model="param[item.fieldName]"
								v-else-if="item.operateType === 'year'" placeholder="选择年份"
								type="year" :disabled="item.noBatch"></el-date-picker> -->
						</slot>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel" type="danger" size="medium">取 消</el-button>
				<el-button type="primary" v-loading="formLoading" @click="determine" size="medium">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 筛选框 -->
		<el-dialog custom-class="custom-search-dialog" title="筛选" v-dialogDrag :visible.sync="openLoding" 
			width="50%" :closeOnClickModal="false" :before-close="clickCancel" top="15vh">
			<template>
				<el-input style="margin-right: 10px; width: 200px; " :placeholder="'请输入' + itemList.orr.name"
					v-model="itemList.userList"></el-input>
				<el-button type="success" plain @click="init(itemList.orr, itemList.userList)">查询</el-button>
				<el-button type="warning" plain @click="clickCancel">取消选择</el-button>
			</template>
			<el-table :data="itemList.list" stripe style="width: 100% " height="60vh" v-loading="itemList.loading"
				@row-dblclick="handleClickChange">
				<el-table-column type="index" width="50">
				</el-table-column>
				<template v-if="['compCode','compName'].includes(itemList.orr.fieldName)">
					<el-table-column prop="compCode" label="生产企业编码" width="180"></el-table-column>
					<el-table-column prop="compName" label="生产企业" width="300"></el-table-column>
				</template>
				<template v-if="['keyCode','keyName','comCode','comName','drugsNature'].includes(itemList.orr.fieldName)">
					<el-table-column prop="keyCode" label="关键字编码" width="120"></el-table-column>
					<el-table-column prop="keyName" label="关键字名称" width="150"></el-table-column>
					<el-table-column prop="comCode" label="药品编码" width="120"></el-table-column>
					<el-table-column prop="comName" label="药品通用名" width="150"></el-table-column>
					<el-table-column prop="drugsNature" label="药品性质" width="120"></el-table-column>
				</template>
				<template v-if="['hospitalName','hospitalLevel','hospitalNature','hospitalCode'].includes(itemList.orr.fieldName)">
					<el-table-column prop="hospitalCode" label="采购医院编码" width="100"></el-table-column>
					<el-table-column prop="hospitalName" label="采购医院" width="250"></el-table-column>
					<el-table-column prop="hospitalLevel" label="医院等级" width="150"></el-table-column>
					<el-table-column prop="hospitalNature" label="医院性质" width="150"></el-table-column>
				</template>
				<template v-if="['provinceCode','provinceName'].includes(itemList.orr.fieldName)">
					<el-table-column prop="provinceCode" label="省份编码" width="150"></el-table-column>
					<el-table-column prop="provinceName" label="省份" width="240"></el-table-column>
				</template>

			</el-table>
			<el-pagination ref="page" v-if="unPage" :current-page.sync="pageParam.pageNo" :page-sizes="pageSizes"
				:page-size="pageParam.pageSize" :layout="pageLayout" :total="pageParam.total"
				@size-change="handleSizeChange" @current-change="handleCurrentChange">
				<slot name="page"></slot>
			</el-pagination>
		</el-dialog>
	</div>
</template>

<script>
import EditUpload from '../pages/make/EditUpload'
export default {
	components: { EditUpload },
	props: ['open', 'cols', 'clickLoading', 'title', 'editPrame', 'userUrl'],
	data() {
		return {
			colsList: [],
			//是否展示分页
			unPage: false,
			pageLayout: 'total, prev, pager, next, jumper, sizes, slot',
			pageSizes: [10, 20, 50, 100],
			pageParam: {
				pageSize: 20,
				pageNo: 1,
				rows: 20,
				total: 0
			},
			formLoading: false,
			itemList: {
				loading: false,
				list: [],
				orr: {},
				userList: ''
			},
			openLoding: false,
			param: {},
			//下拉列表的数据
			list: [],
			pageUrl: {},
			conditionList: [],
			otherParams: {}, //其他参数
		}
	},
	watch: {
		open: {
			immediate: true,
			handler(value) {
				if (value && this.title == '修改') {
					this.param = this.editPrame
				}
			}
		},
		cols: {
			immediate: true,
			handler(value) {
				this.colsList = this.$help.deepClone(value)
				this.colsList.forEach((item, index) => {
					if (item.fieldName === 'comName') {
						let arr = this.colsList[1]
						this.colsList[1] = this.colsList[index]
						this.colsList[index] = arr
					}
				})
			}
		}
	},
	methods: {
		handleSizeChange(val) {
			this.pageParam.pageSize = val
			this.init(this.pageUrl)
		},
		handleCurrentChange(val) {
			this.pageParam.pageNo = val
			this.init(this.pageUrl)
		},
		//筛选框的每行被点击
		handleClickChange(row) {
			this.param = { ...this.param, ...row }
			this.openLoding = false
		},
		//点击筛选
		openFuzzy(item) {
			if(item.param && item.param.stopKey && !this.param[item.param.stopKey]) {
				return this.$message({
					showClose: true,
					message: item.param.placeholder,
					type: 'error'
				});
			}
			this.openLoding = true
			this.itemList.orr = item
			this.conditionList = []
			this.otherParams = {}
			if(item.param && item.param.stopKey && this.param[item.param.stopKey]) {
				this.$set(this.otherParams, item.param.stopKey, this.param[item.param.stopKey])
			}
			this.init(item)
			this.cal()
		},
		cal() {
			this.itemList.list = []
			this.itemList.userList = ''
			this.pageParam = {
				pageSize: 20,
				pageNo: 1,
				rows: 20,
				total: 0
			}
			this.unPage = false
		},
		//筛选框关闭
		clickCancel() {
			this.cal()
			this.openLoding = false
		},
		//取消按钮
		cancel() {
			this.param = {}
			this.clickLoading(false)
		},
		//筛选框查询
		init(item, type) {
			this.$set(this.itemList, 'loading', true)
			//筛选字段
			let screenName = ""
			//下拉接口
			let headIdName = ""
			if (['compCode','compName'].includes(item.fieldName)) { //企业
				headIdName = "setting/aimBaseCompDrugs/queryCompListByKeyword"
				// (旧) setting/aimBaseComp/queryAimBaseCompListByKeyword
			} else if (['comName','comCode','keyCode','keyName','drugsNature'].includes(item.fieldName)) { //药品
				headIdName = "setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword"
			}else if (['hospitalName','hospitalLevel','hospitalNature'].includes(item.fieldName)) {           //医院
				headIdName = "setting/mainfHospitals/queryMainfHospitalsPage"
				screenName = item.fieldName == 'hospitalName' ? 'name' : item.fieldName == 'hospitalLevel' ? 'grade' : item.fieldName == 'hospitalNature' ? 'hospitalType' : ''
			} else if (['provinceCode','provinceName'].includes(item.fieldName)) {           //省份
				headIdName = "setting/sysBaseArea/querySysBaseAreaListByKeyword"
			}

			if (type) {
				this.conditionList.push({
					name: screenName,
					op: 'like',
					value: type
				})
			}
			let orr = {
				page: this.pageParam,
				conditionList: this.conditionList
			}
			if (headIdName != "") {
				
				if (['comName','compName','compCode','comCode','keyCode','keyName','drugsNature'].includes(item.fieldName)) {
					this.$api.get(headIdName, {
						keyword: type, ...this.otherParams
					}).then((res) => {
						if (this.openLoding) {
							this.itemList.list = res.data
						}
					}).finally(() => {
						this.itemList.loading = false
					})
				} else if (item.fieldName == 'provinceCode' || item.fieldName == 'provinceName') {
					this.$api.get(headIdName, {
						keyword: type
					})
						.then((res) => {
							if (this.openLoding) {
								let provinceList = []
								res.data.forEach(item => {
									provinceList.push({
										provinceCode: item.id,
										provinceName: item.fullName,
									})
								});
								this.itemList.list = provinceList
							}
						})
						.finally(() => {
							this.itemList.loading = false
						})
				} else {
					this.unPage = true
					this.$api.post(headIdName, orr)
						.then((res) => {
							let odd = []
							res.data.records.map((item) => {
								if (item.grade && item.hospitalType && item.name) {
									odd.push({
										hospitalCode: item.hospitalCode,
										hospitalName: item.name,
										hospitalLevel: item.grade,
										hospitalNature: item.hospitalType,
									})
								} else {
									odd.push(item)
								}
							})
							if (this.openLoding) {
								this.itemList.list = odd
								this.pageParam.total = res.data.totalCount
								this.pageParam.pageSize = res.data.pageSize
								this.pageUrl = item;
								this.conditionList = []
							}
						})
						.finally(() => {
							this.itemList.loading = false
						})
				}

			}
		},
		//确定按钮
		determine() {
			this.formLoading = true
			if (this.title == '新增') {
				delete this.param.id;
				this.oddInterface('新增成功', this.userUrl)
			} else if (this.title == '修改') {
				this.oddInterface('修改成功', this.userUrl)
			}
		},
		//调用接口
		oddInterface(tit, use) {
			this.$api.post(use, this.param)
				.then((res) => {
					if (res.code === "00000") {
						this.$message({
							showClose: true,
							message: tit,
							type: 'success'
						});
						this.param = {}
						this.clickLoading(false, true)
					} else {
						this.$message({
							showClose: true,
							message: res.message,
							type: 'error'
						});
					}
				})
				.catch(() => {
					this.clickLoading(false, false)
				})
				.finally(() => {
					this.formLoading = false
				})
		}
	}
}
</script>

